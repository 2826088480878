import {Typography} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {Diet, Nutrition, Profile} from "../../modules/profile/ProfileTypes";
import { useDispatch, useSelector } from "react-redux";
import { getDiets, getNutritions, getLanguages } from "../../modules/profile/ProfileActions";
import RenderProfile from "./RenderProfile";
import {ApplicationState} from "../../store";
import Box from "@mui/material/Box";

const ProfileComponent: React.FC = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation();


    let myProfileState = useSelector((state: ApplicationState) => state.profile)
    let myConfigState = useSelector((state: ApplicationState) => state.config)

    let activeProfile: Profile = Object.values(myProfileState.profiles).find(profile => profile.id === myProfileState.activeProfileID)

    const patientProfile = myProfileState.patient
    const guestProfiles = myProfileState.guests
    const featureShowNutrition = myConfigState.showNutrition
    const featureChangeNutrition = myConfigState.changeNutrition
    const allNutritions = myProfileState ? myProfileState.nutritions : null
    const featureChangeDiet = myConfigState.changeDiet
    const allDiets = myProfileState ? myProfileState.diets : null

    const getAllDiets = (profile: Profile) => {
        if (featureChangeDiet) {
            getDiets(profile)(dispatch)
        }
    }

    const getAllNutritions = (profile: Profile) => {
        if (featureChangeNutrition) {
            getNutritions(profile)(dispatch)
        }
    }

    const getCurrentLanguages = () => {
        getLanguages()(dispatch)
    }

    React.useEffect(() => {
        getAllNutritions(activeProfile);
        getAllDiets(activeProfile);
        getCurrentLanguages()
    }, [activeProfile]);


    return (<Box sx={{m: '2em'}}>
        <Typography
            sx={{ textAlign: 'left', fontWeight: '600' }}>{t("profiel.Patiënt").toUpperCase()}</Typography>
        {renderPatient(patientProfile, featureShowNutrition, featureChangeNutrition, allNutritions, featureChangeDiet, allDiets, patientProfile, activeProfile)}

        {guestProfiles && guestProfiles.length > 0 &&
            <Typography sx={{
                textAlign: 'left',
                marginTop: '2em',
                fontWeight: '600'
            }}>{t("profiel.Gasten")}</Typography>
        }
        {guestProfiles.map(guest => renderGuest(guest, featureShowNutrition, featureChangeNutrition, allNutritions, featureChangeDiet, allDiets, patientProfile, activeProfile))}
    </Box>)
}

export default ProfileComponent

const renderPatient = (profile: Profile, featureShowNutrition: boolean, featureChangeNutrition: boolean, allNutritions: Nutrition[], featureChangeDiet: boolean, allDiets: Diet[], patientProfile: Profile, activeProfile: Profile) => {
    return <RenderProfile allDiets={allDiets} featureShowNutrition={featureShowNutrition} featureChangeNutrition={featureChangeNutrition} allNutritions={allNutritions} featureChangeDiet={featureChangeDiet} patientProfile={patientProfile}
                          profile={profile} activeProfile={activeProfile}/>

}

const renderGuest = (profile: Profile, featureShowNutrition: boolean, featureChangeNutrition: boolean, allNutritions: Nutrition[], featureChangeDiet: boolean, allDiets: Diet[], patientProfile: Profile, activeProfile: Profile) => {
    return <Box key={profile.id} sx={{mb: '25px'}}>
        <RenderProfile allDiets={allDiets} featureShowNutrition={featureShowNutrition} featureChangeNutrition={featureChangeNutrition} allNutritions={allNutritions} featureChangeDiet={featureChangeDiet} patientProfile={patientProfile}
                       profile={profile} activeProfile={activeProfile}/>
    </Box>
}