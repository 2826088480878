import * as React from 'react';
import {Typography, Paper, FormControl, FormControlLabel, Checkbox, FormHelperText} from '@mui/material';
import {useTranslation} from "react-i18next";
import {approveDisclaimer} from "../../modules/profile/ProfileActions";
import {useDispatch, useSelector} from "react-redux";
import i18n from "i18next";
import {ApplicationState} from "../../store";
import Box from "@mui/material/Box";

export interface DisclaimerProps {
    content: string;
    isAccepted: boolean;
    canChangeDiet: boolean;
}

export interface DisclaimerDispatchProps {
    approve: () => void;
}

const DisclaimerComponent: React.FC = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation();

    const profile = useSelector((state: ApplicationState) => state.profile)
    const myConfigState = useSelector((state: ApplicationState) => state.config)
    const patient = profile.patient;

    const content = patient.disclaimers.find(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()) === undefined ? t("profiel.Disclaimer1") + " " + t("profiel.Disclaimer2") : patient.disclaimers.find(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()).disclaimer
    const isAccepted = patient.disclaimerAccepted
    const canChangeDiet = myConfigState.changeDiet

    const handleApproveDisclaimer = (checked: boolean) => {
        console.log('approveDisclaimer component')
        if(checked) approveDisclaimer()(dispatch)
    }

    return <Box sx={{m: '2em'}}>
        <Typography sx={{textAlign: 'left', fontWeight: '600'}}>DISCLAIMER</Typography>
        <Paper square elevation={1} sx={{p: '2em'}}>
            <Typography sx={{textAlign: 'left'}}>{content}</Typography>
            <FormControl required>
                <FormControlLabel control={
                    <Checkbox checked={isAccepted}
                              disabled={isAccepted}
                              color="primary"
                              onChange={(e, checked) => handleApproveDisclaimer(checked)}
                    />}
                                  label={t("profiel.Disclaimer")}/>
                {!isAccepted && canChangeDiet &&
                    <FormHelperText>{t("profiel.DisclaimerError")}</FormHelperText>
                }
            </FormControl>

        </Paper>

    </Box>

}

export default DisclaimerComponent